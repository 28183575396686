import React, { useState } from "react"
import PostCardList from "components/post-card-list/post-card-list"
import PropTypes from "prop-types"

const PostsFeed = props => {
  const { className, config, postsFeed, language } = props
  const [initialPosts] = useState(
    postsFeed.nodes.slice(0, config.numberOfLatestPosts)
  )
  const initialPostsSlugs = initialPosts.map(element => element.slug)
  return config?.showLatestPosts && initialPosts?.length ? (
    <section className={className}>
      {config.title ? (
        <div className="posts-feed-title">{config.title}</div>
      ) : null}
      {config.description ? (
        <div className="posts-feed-description">{config.description}</div>
      ) : null}
      <PostCardList
        class="homepage-post-card-list"
        enableLoadMore={true}
        loadMorePostsFilterCallback={element => {
          return (
            !initialPostsSlugs.includes(element.slug) &&
            element.language.code === language.code
          )
        }}
        loadMoreText={config.loadMoreButtonText}
        nFirstPagePosts={config.numberOfLatestPosts}
        nMorePostsToLoad={config.loadMorePostsPerPage}
        postsList={initialPosts}
      ></PostCardList>
    </section>
  ) : null
}

PostsFeed.propTypes = {
  className: PropTypes.string,
  config: PropTypes.object,
  language: PropTypes.object,
  latestPostsFeedSlugs: PropTypes.array,
  postsFeed: PropTypes.object,
}

export default PostsFeed
