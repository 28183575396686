import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import SEO from "@bufox/gatsby-utils/fe/wp/wpSeoHelmet"
import AboutUs from "@bufox/gatsby-theme-dry/src/components/homepage/about-us"
import WhatIDo from "../../../components/homepage/what-i-do"
import Services from "../../../components/homepage/services"
import FollowMe from "../../../components/homepage/follow-me"
import Contacts from "@bufox/gatsby-theme-dry/src/components/homepage/contacts"
import PostsFeed from "@bufox/gatsby-theme-dry/src/components/homepage/posts-feed"
import Layout from "components/layouts/base-layout/base-layout"
import themeConfig from "utils/theme-config"
const { screens } = themeConfig

const Homepage = props => {
  if (!props.data || !props.data.wpPage) {
    return null
  }
  const {
    className,
    location,
    data: {
      wpPage: {
        excerpt,
        featuredImage,
        language,
        seo,
        title,
        translations,
        template,
        uri,
      },
      latestPostsFeed,
      rawMenus,
      wpSettings,
      commonThemeSettings,
      searchUrls,
    },
  } = props

  if (!commonThemeSettings) {
    // no default theme settings page for the current language
    return (
      <div>
        Please create a settings page for the current language: {language.code}
      </div>
    )
  }
  if (!template?.acfHomepage) {
    return (
      <div>
        Please select the correct template for the Homepage of the current
        language: {language.code}
      </div>
    )
  }
  const homepageSections = template.acfHomepage
  const myValentinaSections = template.myValentinaHomepage
  const {
    acfThemeSettings: { generalSettings, footer, search, socialNetworks },
  } = commonThemeSettings

  return (
    <Layout
      className={className}
      generalSettings={generalSettings}
      homepageUri={uri}
      footer={footer}
      location={location}
      language={language}
      rawMenus={rawMenus}
      searchSettings={search}
      searchUrls={searchUrls}
      socialNetworks={socialNetworks}
      wpSettings={wpSettings}
      translations={translations}
    >
      <div>
        <SEO
          featuredImage={featuredImage ? featuredImage.node : null}
          language={language}
          excerpt={excerpt}
          seo={seo}
          siteUrl={process.env.GATSBY_SITE_URL}
          title={title}
          translations={translations}
        />
        <main className="main-content">
          {homepageSections?.aboutUs && (
            <AboutUs config={homepageSections.aboutUs} />
          )}
          {myValentinaSections.whatIDo ? (
            <WhatIDo
              className="what-i-do"
              config={myValentinaSections.whatIDo}
            />
          ) : null}
          {myValentinaSections.services ? (
            <Services
              className="services"
              config={myValentinaSections.services}
            />
          ) : null}
          {homepageSections?.postsFeed && (
            <PostsFeed
              className="dry-container posts-feed"
              config={homepageSections.postsFeed}
              language={language}
              postsFeed={latestPostsFeed}
            />
          )}
          {myValentinaSections?.followMe && (
            <FollowMe
              config={myValentinaSections.followMe}
              socialNetworks={socialNetworks}
            />
          )}
          {homepageSections?.contacts && (
            <Contacts contacts={homepageSections.contacts} />
          )}
        </main>
      </div>
    </Layout>
  )
}

Homepage.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    defaultCategory: PropTypes.object,
    firstLevelCategories: PropTypes.object,
    latestPostsFeed: PropTypes.object,
    rawMenus: PropTypes.object,
    wpPage: PropTypes.shape({
      content: PropTypes.string,
      dateGmt: PropTypes.string,
      excerpt: PropTypes.string,
      featuredImage: PropTypes.object,
      language: PropTypes.object,
      acfHomepage: PropTypes.shape({
        aboutUs: PropTypes.object,
        categoriesList: PropTypes.object,
        postsFeed: PropTypes.object,
        contacts: PropTypes.object,
      }),
      seo: PropTypes.object,
      slug: PropTypes.string,
      template: PropTypes.object,
      title: PropTypes.string,
      translations: PropTypes.array,
      uri: PropTypes.string,
    }),
    wpSettings: PropTypes.object,
    postsFeed: PropTypes.object,
    commonThemeSettings: PropTypes.object,
    searchUrls: PropTypes.object,
  }),
  location: PropTypes.object,
}

const StyledHomepage = styled(Homepage)`
  .homepage-title,
  .posts-feed-title,
  .contacts-title {
    font-size: 2rem;
    line-height: 2.2rem;
    text-transform: uppercase;
  }
  .about-us {
    background-color: var(--gray2);
    color: var(--dark);
    padding: 1rem 0 4rem;
  }
  .about-us-title {
    display: none;
  }
  .about-us-cta {
    text-align: center;
    a:hover {
      background-color: var(--primary-dark);
    }
  }
  .about-us-description {
    font-size: 1.3rem;
    position: relative;
    margin: 0 auto;
    padding: 3rem 0;
    max-width: 80%;
    font-size: 1.2rem;
    text-align: center;
    &:before {
      position: absolute;
      top: 0;
      width: 60%;
      height: 3px;
      background-color: var(--primary);
      content: "";
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .social-networks-bar {
    color: var(--gray5);
    font-size: 1.5rem;
  }

  .toggle-search-button {
    color: var(--gray5);
  }

  .posts-feed {
    padding: 0;
    text-align: center;
  }
  .posts-feed-title {
    position: relative;
    padding: 3rem 0;
    margin: 2rem 0;
    &:after {
      background-color: var(--primary);
      height: 3px;
      width: 60%;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      content: "";
      position: absolute;
    }
  }
  .post-card-list {
    padding: 0 1rem;
  }
  .posts-feed-description {
    padding: 2rem 0 3rem;
    font-size: 1.2rem;
  }

  .post-title {
    font-size: 1.4rem;
    line-height: 1.75rem;
  }

  .contacts {
    background-color: var(--tertiary);
    padding: 1rem 2rem 6rem;
    margin: 6rem 0 0 0;
    text-align: center;
  }

  .contacts-title {
    position: relative;
    padding: 3rem 0;
    margin: 3rem 0;
    &:after {
      background-color: var(--primary);
      height: 3px;
      width: 20%;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      content: "";
      position: absolute;
    }
  }

  .contacts-description {
    margin: 3rem 0;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  @media only screen and (min-width: ${screens.lg}) {
    .header-sections {
      padding-top: 5rem;
    }
    .desktop-search-box {
      color: var(--gray5);
    }
    .toggle-search-button {
      font-size: 1.7rem;
    }

    .about-us {
      padding-bottom: 8rem;
    }
    .about-us-title {
      display: block;
      font-size: 4rem;
      text-align: center;
      letter-spacing: 0.2rem;
      padding-bottom: 3rem;
      &::after {
        display: none;
      }
    }

    .about-us-description {
      &::before {
        width: 12rem;
      }
    }

    .posts-feed {
      padding: 0 2rem;
    }

    .posts-feed-title {
      text-align: left;
      &:after {
        width: 6rem;
        right: auto;
      }
    }

    .posts-feed-description {
      text-align: left;
    }

    .post-card-list {
      padding: 0;
      li:nth-child(2n + 1) {
        padding-left: 0;
      }
      li:nth-child(2n) {
        padding-right: 0;
      }
    }

    .contacts-title {
      &:after {
        width: 7rem;
      }
    }
  }
  @media only screen and (min-width: ${screens.xl}) {
    .posts-feed {
      padding: 0;
    }
  }
  @media only screen and (min-width: ${screens["2xl"]}) {
    .post-card-list {
      li {
        width: 50%;
      }
    }
  }
`

export const pageQuery = graphql`
  query MyvalentinaHomepage(
    $id: String
    $languageCode: WpLanguageCodeEnum
    $menusLocations: [WpMenuLocationEnum]
    $defaultCategoryId: String
    $themeSettingsPageId: String
  ) {
    wpPage(id: { eq: $id }) {
      ...PostInfo
      template {
        ... on WpTemplate_Homepage {
          acfHomepage {
            aboutUs {
              description
              cta
              image {
                ...FullWidthGatsbyImage
              }
              link {
                ... on WpCommonPost {
                  id
                  slug
                  uri
                }
              }
              title
            }
            categoriesList {
              enableCategoriesFeed
            }
            postsFeed {
              title
              description
              enableLoadMore
              fieldGroupName
              loadMoreButtonText
              loadMorePostsPerPage
              numberOfLatestPosts
              showLatestPosts
            }
            contacts {
              description
              cta
              link {
                ... on WpCommonPost {
                  id
                  slug
                  uri
                }
              }
              title
            }
          }
          myValentinaHomepage {
            whatIDo {
              title
              description
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      placeholder: BLURRED
                      width: 400
                      height: 500
                    )
                  }
                }
              }
            }
            followMe {
              title
              description
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      placeholder: BLURRED
                      width: 400
                    )
                  }
                }
              }
            }
            services {
              title
              description
              servicesList {
                description
                title
              }
            }
            newsletter {
              title
              description
              emailPlaceholder
              cta
              image {
                ...FullWidthGatsbyImage
              }
              privacyText
            }
          }
        }
      }
    }
    firstLevelCategories: allWpCategory(
      filter: {
        language: { code: { eq: $languageCode } }
        parentDatabaseId: { eq: null }
      }
      sort: { order: ASC, fields: acfTaxonomy___termOrder }
    ) {
      nodes {
        id
        uri
        name
        slug
      }
    }
    latestPostsFeed: allWpPost(
      limit: 12
      filter: { language: { code: { eq: $languageCode } } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...DryPostCardInfo
        id
        categories {
          nodes {
            name
            id
            slug
            uri
          }
        }
      }
    }
    defaultCategory: wpCategory(id: { eq: $defaultCategoryId }) {
      id
      slug
      name
    }
    ...LocalizedMenus
    ...WpSettings
    ...CommonThemeSettings
    ...SearchUrls
  }
`
export default StyledHomepage
