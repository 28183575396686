import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import themeConfig from "utils/theme-config"
const { screens } = themeConfig

const WhatIDo = ({ className, config }) => {
  return config.title || config.description ? (
    <section className={className}>
      <div className="inner-what-i-do dry-container">
        {config.title ? (
          <div className="homepage-title what-i-do-title">{config.title}</div>
        ) : null}
        {config?.image?.localFile?.childImageSharp?.gatsbyImageData ? (
          <div className="what-i-do-image-container">
            <div>
              <GatsbyImage
                image={config.image.localFile.childImageSharp.gatsbyImageData}
                className="what-i-do-image"
                alt={config.image?.altText ? config.image.altText : ""}
                loading="lazy"
              />
            </div>
          </div>
        ) : null}
        {config.description ? (
          <div
            className="what-i-do-description"
            dangerouslySetInnerHTML={{
              __html: config.description,
            }}
          ></div>
        ) : null}
      </div>
    </section>
  ) : null
}

WhatIDo.propTypes = {
  className: PropTypes.string,
  config: PropTypes.object,
}

const StyledWhatIDo = styled(WhatIDo)`
  margin: 3rem 0;
  text-align: center;
  padding: 0 2rem;

  .what-i-do-title {
    width: 50%;
    margin: 0 auto;
    padding: 3rem 0;
    position: relative;
    &:after {
      background-color: var(--primary);
      height: 3px;
      width: 60%;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      content: "";
      position: absolute;
    }
  }
  .what-i-do-image-container {
    padding: 6rem 2rem 4rem 20%;
    position: relative;
    > div {
      position: relative;
      z-index: 2;
      overflow: visible;
      &:before {
        position: absolute;
        left: -20%;
        top: -10%;
        width: 40%;
        background-color: var(--secondary);
        height: 100%;
        content: "";
        z-index: -1;
      }
    }
  }

  .what-i-do-description {
    text-align: left;
    font-size: 1.2rem;
    p {
      margin: 0 0 1rem 0;
    }
  }

  @media only screen and (min-width: ${screens.lg}) {
    margin: 12rem 0 6rem;

    .inner-what-i-do {
      display: flex;
      align-items: flex-start;
    }

    .what-i-do-title {
      margin: 0 6% 0 4%;
      padding: 1.5rem 0;
      flex-basis: 20%;
      text-align: right;
      font-weight: 500;
      &::after {
        width: 30%;
        right: 4%;
        left: auto;
      }
    }
    .what-i-do-image-container {
      padding: 0 2rem;
      flex-basis: 35%;
    }
    .what-i-do-description {
      flex-basis: 45%;
      padding: 0 5%;
    }
  }
`

export default StyledWhatIDo
