import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import themeConfig from "utils/theme-config"
import SocialNetworks from "@bufox/gatsby-theme-dry/src/components/social-networks/social-networks"

const { screens } = themeConfig

const FollowMe = ({ className, config, socialNetworks }) => {
  return config.title || config.description ? (
    <section className={className}>
      <div className="inner-follow-me dry-container">
        <div className="follow-me-section-1">
          {config.title ? (
            <div className="homepage-title follow-me-title">{config.title}</div>
          ) : null}
          {config.description ? (
            <div
              className="follow-me-description"
              dangerouslySetInnerHTML={{
                __html: config.description,
              }}
            ></div>
          ) : null}
          <SocialNetworks
            className="social-networks"
            socialNetworks={socialNetworks}
          ></SocialNetworks>
        </div>
        {config?.image?.localFile?.childImageSharp?.gatsbyImageData ? (
          <div className="follow-me-section-2">
            <div>
              <GatsbyImage
                image={config.image.localFile.childImageSharp.gatsbyImageData}
                className="follow-me-image"
                alt={config.image?.altText ? config.image.altText : ""}
                loading="lazy"
              />
            </div>
          </div>
        ) : null}
      </div>
    </section>
  ) : null
}

FollowMe.propTypes = {
  className: PropTypes.string,
  config: PropTypes.object,
  socialNetworks: PropTypes.array,
}

const StyledFollowMe = styled(FollowMe)`
  margin: 3rem 0 6rem;
  padding: 0 2rem;
  text-align: center;

  .follow-me-title {
    width: 50%;
    margin: 0 auto;
    padding: 3rem 0;
    position: relative;
    &:after {
      background-color: var(--primary);
      height: 3px;
      width: 60%;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      content: "";
      position: absolute;
    }
  }
  .follow-me-section-2 {
    padding: 6rem 2rem 4rem 20%;
    position: relative;
    > div {
      position: relative;
      z-index: 2;
      overflow: visible;
      &:before {
        position: absolute;
        left: -20%;
        top: 15%;
        width: 40%;
        background-color: var(--secondary);
        height: 100%;
        content: "";
        z-index: -1;
      }
    }
  }
  .follow-me-description {
    margin: 3rem 0;
    font-size: 1.2rem;
    p {
      margin: 0 0 1rem 0;
    }
  }

  .social-networks-items {
    font-size: 2.5rem;
    color: var(--gray5);
  }

  @media only screen and (min-width: ${screens.lg}) {
    margin: 6rem 0;

    .inner-follow-me {
      display: flex;
      padding: 0 2rem;
    }

    .follow-me-section-1 {
      order: 2;
      text-align: left;
      flex-basis: 60%;
    }
    .follow-me-section-2 {
      order: 1;
      padding: 0 2rem;
      flex-basis: 40%;
    }

    .follow-me-title {
      margin-left: 0;
      margin-right: 0;
      padding-left: 3rem;
      &::after {
        width: 30%;
        left: 3rem;
        right: auto;
      }
    }

    .follow-me-description {
      flex-basis: 45%;
      margin-left: 0;
      margin-right: 0;
      padding-left: 3rem;
    }
    .social-networks-items {
      padding-left: 3rem;
      justify-content: left;
    }
  }
`

export default StyledFollowMe
