import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Contacts = ({ contacts }) => {
  return contacts?.title ||
    contacts?.description ||
    (contacts?.cta && contacts?.link?.uri) ? (
    <section className="contacts">
      <div className="contacts-content dry-container">
        {/* Contacts section: {JSON.stringify(contacts)} */}
        {contacts.title && (
          <div className="contacts-title with-separator">{contacts.title}</div>
        )}
        {contacts.description && (
          <div className="contacts-description">{contacts.description}</div>
        )}
        {contacts.cta && contacts?.link?.uri && (
          <div className="btn-primary contacts-cta">
            <Link to={contacts.link.uri}> {contacts.cta} </Link>
          </div>
        )}
      </div>
    </section>
  ) : null
}

Contacts.propTypes = {
  contacts: PropTypes.object,
}

export default Contacts
