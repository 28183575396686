import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import themeConfig from "utils/theme-config"
const { screens } = themeConfig

const Services = ({ className, config }) => {
  return config.title || config.description ? (
    <section className={className}>
      <div className="inner-services dry-container">
        {config.title ? (
          <div className="homepage-title services-title">{config.title}</div>
        ) : null}
        {config.description ? (
          <div
            className="services-description"
            dangerouslySetInnerHTML={{
              __html: config.description,
            }}
          ></div>
        ) : null}
        {config.servicesList?.length ? (
          <div className="services-list">
            {config.servicesList.map((service, i) => (
              <div className="service" key={`service-${i}`}>
                <div className="service-title">{service.title}</div>
                <div
                  className="service-description"
                  dangerouslySetInnerHTML={{
                    __html: service.description,
                  }}
                ></div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </section>
  ) : null
}

Services.propTypes = {
  className: PropTypes.string,
  config: PropTypes.object,
}

const StyledServices = styled(Services)`
  font-size: 1.2rem;
  background-color: var(--tertiary);
  padding: 1rem 2rem;
  margin: 3rem 0;

  .services-title {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.3rem;
    position: relative;
    text-align: center;
    padding: 2rem 0;
    margin: 2rem 0;
    &:after {
      background-color: var(--primary);
      height: 3px;
      width: 60%;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      content: "";
      position: absolute;
    }
  }

  .services-description {
    margin: 3rem 0;
    p {
      margin: 0 0 1rem 0;
    }
  }

  .service {
    margin: 2rem 0 3rem 0;
  }
  .service-title {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.3rem;
    margin: 0 0 1rem 0;
  }
  @media only screen and (min-width: ${screens.lg}) {
    .services-title {
      text-align: left;
      &:after {
        width: 5rem;
        left: 0;
        right: auto;
      }
    }
    .services-description {
      margin-bottom: 1rem;
    }

    .services-list {
      display: flex;
      flex-wrap: wrap;
    }
    .service {
      width: 50%;
      padding-right: 4rem;
    }
  }
`

export default StyledServices
