import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const AboutUs = ({ config }) => {
  return config.title && config.description ? (
    <section className="about-us">
      {config?.image?.localFile?.childImageSharp?.gatsbyImageData ? (
        <div className="about-us-image-container">
          <GatsbyImage
            image={config.image.localFile.childImageSharp.gatsbyImageData}
            className="gi-overlay about-us-image"
            alt={config.image?.altText ? config.image.altText : ""}
            loading="lazy"
          />
        </div>
      ) : null}
      <div className="about-us-content dry-container">
        {config.title ? (
          <div className="about-us-title with-separator">{config.title}</div>
        ) : null}
        {config.description ? (
          <div
            className="about-us-description"
            dangerouslySetInnerHTML={{
              __html: config.description,
            }}
          ></div>
        ) : null}
        {config.cta && config.link?.uri ? (
          <div className="btn-primary about-us-cta">
            <Link to={config.link.uri}> {config.cta} </Link>
          </div>
        ) : null}
      </div>
    </section>
  ) : null
}

AboutUs.propTypes = {
  config: PropTypes.object,
}

export default AboutUs
